// src/App.js
import React from "react";
import { Layout, Table, Typography, Button, Row, Col, Image } from "antd";
import { Link } from "react-router-dom";
// import "antd/dist/antd.css"; // Ensure this is correctly imported

const { Title } = Typography;
const { Header, Content } = Layout;

const columns = [
  {
    title: "Shot#",
    dataIndex: "shotNumber",
    key: "shotNumber",
  },
  {
    title: "INT/EXT",
    dataIndex: "intExt",
    key: "intExt",
  },
  {
    title: "Dialogue",
    dataIndex: "dialogue",
    key: "dialogue",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Shot Size",
    dataIndex: "shotSize",
    key: "shotSize",
  },
  {
    title: "Camera Angle",
    dataIndex: "cameraAngle",
    key: "cameraAngle",
  },
  {
    title: "Camera Movement",
    dataIndex: "cameraMovement",
    key: "cameraMovement",
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
  },
];

const data = [
  {
    key: "1",
    shotNumber: "1",
    intExt: "INT",
    dialogue: 'JESSICA: "Look at those ACTIV tokens stacking up!"',
    action: "(Excitedly checking her fitness tracker)",
    shotSize: "Medium Shot",
    cameraAngle: "Low",
    cameraMovement: "Pan Right",
    notes: "The room is adorned with motivational quotes and fitness awards",
  },
];

const Withpic = () => (
  <Layout>
    <Header style={{ background: "#fff", padding: 0 }}>
      <Title level={4} style={{ margin: "16px" }}>
        Project Name
      </Title>
    </Header>
    <Content style={{ margin: "16px" }}>
      <Row>
        <Col span={24}>
          <Title level={5}>Sc.1 INT. Apartment - Morning</Title>
          <Link to="/pic-edit">
            <Image
              width={200}
              src="pic.png" // replace with the actual path to your image
              alt="Storyboard"
            />
          </Link>
        </Col>
      </Row>
      <Table columns={columns} dataSource={data} pagination={false} />
    </Content>
    <Button
      type="primary"
      style={{ position: "absolute", top: "16px", right: "16px" }}
    >
      Share
    </Button>
  </Layout>
);

export default Withpic;
