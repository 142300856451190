import React from "react";
import ScriptSideNavigation from "./scriptSideNavigation";
import scriptdashboard from "../../assets/scriptdashboard.png";
import { Link } from "react-router-dom";

function ScriptDashboard() {
  return (
    <div className="flex w-full h-screen">
      <ScriptSideNavigation />
      <main className="flex-1">
        <div className="flex justify-center flex-col items-center  overflow-y-hidden">
          <div className="bg-white p-10 flex justify-between items-center py-10  -ml-20">
            {/* <h1 className="text-lg font-semibold">Script</h1> */}
            <div>
              <Link to="/title-script">
                <button className="bg-pink-400 text-white px-10 py-2 rounded mr-20">
                  + Upload your script
                </button>
              </Link>
              <Link to="/write-title">
                <button className="bg-pink-400 text-white px-10 py-2 rounded mr-20">
                  + Write your script
                </button>
              </Link>
              <Link to="/*">
                <button className="bg-pink-400 text-white px-10 py-2 rounded">
                  + Concept
                </button>
              </Link>
            </div>
          </div>
          <div className="">
            <img
              src={scriptdashboard || "https://placehold.co/886x635"}
              alt="Illustration of a person sitting at a desk with a laptop, papers, and a potted plant, with a window in the background."
              className="w-10/12 h-auto overflow-hidden"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default ScriptDashboard;
