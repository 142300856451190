import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <Link to="/">
        <div className="underline text-bold text-4xl">Page Not Found</div>
      </Link>
    </div>
  );
}

export default PageNotFound;
