import React, { useState } from "react";
import scriptLogo from "../../assets/scriptLogo.png";
import Hoome from "../../assets/homelogo.png";
import Ibox from "../../assets/belllogo.png";
import Tea from "../../assets/teamlogo.png";
import Scri from "../../assets/scriptlogoo.png";
import Sto from "../../assets/storyboardlogo.png";
import Moo from "../../assets/moodboardlogo.png";
import Shor from "../../assets/shorlistlogo.png";
import Brea from "../../assets/scriptbreaddownlogo.png";
import { Link } from "react-router-dom";

function ScriptSideNavigation() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const navigationItems = [
    { icon: Hoome, text: "Home", url: "/" },
    { icon: Ibox, text: "Inbox", url: "/inbox" },
    { icon: Tea, text: "Teams", url: "/teams" },
    { icon: Scri, text: "Script", url: "/script-dashboard" },
    { icon: Sto, text: "Storyboard", url: "/storygenerater" },
    { icon: Moo, text: "Moodboard", url: "/create-board" },
    { icon: Shor, text: "Shortlist", url: "/city-images" },
    { icon: Brea, text: "Script Breakdown", url: "/script-breakdown" },
  ];

  return (
    <aside
      className={`fixed left-0 bottom-0 top-0 bg-white shadow-md ${
        isOpen ? "block" : "block"
      }`}
    >
      <div className="flex  justify-end">
        <button
          className="text-black focus:outline-none "
          onClick={toggleSidebar}
        >
          {isOpen ? (
            <i class="fa fa-angle-double-left" aria-hidden="true"></i>
          ) : (
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
          )}
          {/* <i className="fas fa-bars"></i> */}
        </button>
      </div>

      <div className="px-4 py-6">
        <div
          className={`${
            isOpen ? "w-50 items-start" : "w-10 items-center"
          } transition ease-in-out delay-150 flex  flex-col`}
        >
          <div className="flex items-center mb-4">
            <img src={scriptLogo} alt="Logo" className="h-15 mr-2" />
            {/* <span className="font-semibold text-lg">Your App</span> */}
          </div>
          <div className="mt-8">
            <ul>
              {navigationItems.map((item, index) => (
                <li key={index} className="mb-6">
                  <Link to={item.url}>
                    <button className="focus:outline-none flex">
                      {/* <i className={`fas ${item.icon}`}></i> */}

                      <img src={item.icon} className="h-5" />
                      {isOpen && <span className="ml-2">{item.text}</span>}
                    </button>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="absolute bottom-0">
              <ul>
                <li className="mb-6">
                  <button className="focus:outline-none">
                    <i className="fas fa-cog"></i>
                    {isOpen && <span className="ml-2">Settings</span>}
                  </button>
                </li>
                <li className="mb-6">
                  <button className="focus:outline-none">
                    <i className="fas fa-question-circle"></i>
                    {isOpen && <span className="ml-2">Help</span>}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* {!isOpen && (
          <div className="mt-8">
            <div className="flex items-center mb-4">
              <img src={scriptLogo} alt="Logo" className="h-8 mr-2" />
            </div>
            <ul>
              <li className="mb-6">
                <button className="focus:outline-none">
                  <i className="fas fa-home"></i>
                </button>
              </li>
              <li className="mb-6">
                <button className="focus:outline-none">
                  <i className="fas fa-inbox"></i>
                </button>
              </li>
              <li className="mb-6">
                <button className="focus:outline-none">
                  <i className="fas fa-users"></i>
                </button>
              </li>
              <li className="mb-6 text-pink-500">
                <button className="focus:outline-none">
                  <i className="fas fa-file-alt"></i>
                </button>
              </li>
              <li className="mb-6">
                <button className="focus:outline-none">
                  <i className="fas fa-cog"></i>
                </button>
              </li>
              <li className="mb-6">
                <button className="focus:outline-none">
                  <i className="fas fa-question-circle"></i>
                </button>
              </li>
            </ul>
          </div>
        )} */}
      </div>
    </aside>
  );
}

export default ScriptSideNavigation;
