import React from "react";
import { Link } from "react-router-dom";
import SideNavigation from "./sideNatigation";

const Visual = () => {
  return (
    <div>
      <SideNavigation />
      <div className="flex-1 bg-gray-100 home-main flex flex-col justify-between h-screen">
        <div style={styles.content}>
          <h1 style={styles.title}>Vision to visuals</h1>
          <p style={styles.subtitle}>Storyboard in Creation ...</p>
          <Link to="/upload-stories">
            <button style={styles.button}>Advanced Version coming soon</button>
          </Link>
          <p style={styles.description}>
            best used for moodboards and visualizing your script
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f8f8f8",
    margin: 0,
  },
  content: {
    textAlign: "center",
    background: "white",
    padding: "20px",
    borderRadius: "8px",

    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "2em",
    color: "#333",
    marginBottom: "0.5em",
  },
  subtitle: {
    fontSize: "1em",
    color: "#777",
    margin: "0.5em 0",
  },
  button: {
    backgroundColor: "#a84fff",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "1em",
    cursor: "pointer",
    marginTop: "1em",
  },
  description: {
    marginTop: "1.5em",
    fontSize: "0.9em",
    color: "#777",
  },
};

export default Visual;
