import React from "react";
import ReactDOM from "react-dom";
import { Layout, Menu, Button, Row, Col, Typography } from "antd";
import {
  EyeOutlined,
  SendOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";
import SideNavigation from "./sideNatigation";

const { Header, Content, Sider } = Layout;
const { Text } = Typography;

const Generatestory = () => {
  return (
    <div>
      <SideNavigation />
      <div className="flex-1 bg-gray-100 home-main flex flex-col justify-between h-screen">
        <Layout style={{ minHeight: "100vh" }}>
          <Header className="header" style={styles.header}>
            <div className="logo" style={styles.logo}>
              Storyboard
            </div>
            <div className="icons" style={styles.icons}>
              <EyeOutlined className="icon" style={styles.icon} />
              <SendOutlined className="icon" style={styles.icon} />
              <SettingOutlined className="icon" style={styles.icon} />
              <UserOutlined
                className="icon user-icon"
                style={styles.userIcon}
              />
            </div>
          </Header>
          <Layout>
            <Sider
              width={80}
              className="site-layout-background"
              style={styles.sider}
            >
              <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                style={styles.menu}
              >
                <Menu.Item key="1">1</Menu.Item>
                <Menu.Item key="2">2</Menu.Item>
                <Menu.Item key="3">3</Menu.Item>
                <Menu.Item key="4">4</Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{ padding: "24px" }}>
              <Content
                className="site-layout-background"
                style={styles.content}
              >
                <Row justify="center" align="middle" style={{ height: "100%" }}>
                  <Col style={{ textAlign: "center" }}>
                    <div
                      className="ai-logo"
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src="gpt.png" />
                    </div>
                    <Text>
                      You can choose to generate your whole storyboard or shots
                      from your storyboard by the help of our AI Assistant
                    </Text>
                    <div style={{ marginTop: 20 }}>
                      <Link to="/visual">
                        <Button
                          type="primary"
                          style={{
                            marginRight: 10,
                            backgroundColor: "#F365C1",
                            width: 170, // Adjust width as needed
                            height: 40, // Adjust height as needed
                          }}
                        >
                          Generate Storyboard
                        </Button>
                      </Link>
                      <Link to="/pic-stories">
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "#F365C1",
                            width: 170, // Same width as the first button
                            height: 40, // Same height as the first button
                          }}
                        >
                          Choose Shots
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    padding: "0 20px",
  },
  logo: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  icons: {
    display: "flex",
  },
  icon: {
    fontSize: "20px",
    marginLeft: "20px",
  },
  userIcon: {
    border: "1px solid #ccc",
    borderRadius: "50%",
    padding: "3px",
  },
  sider: {
    background: "#fff",
  },
  menu: {
    height: "100%",
    borderRight: 0,
  },
  content: {
    padding: 24,
    margin: 0,
    minHeight: 280,
    background: "#fff",
  },
};

export default Generatestory;
