import React, { useState } from "react";
import ScriptSideNavigation from "./scriptSideNavigation";
import ReactQuill from "react-quill";
import "../../App.css";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import Mess from "../../assets/Mes.png";
import User from "../../assets/user.png";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";

const CustomToolbar = () => (
  <div className="toolbar">
    <select className="ql-header">
      <option value="1">Scene Heading</option>
      <option value="2">Act</option>
      <option value="3">Action</option>
      <option value="4">Character</option>
      <option value="5">Dialogue</option>
      <option value="6">Parenthetical</option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
  </div>
);

const formats = ["header", "bold", "italic", "underline"];

function Writescript() {
  const [value, setValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modules = {
    toolbar: {
      container: ".toolbar",
    },
  };

  return (
    <div className="flex w-full h-screen overflow-hidden">
      <ScriptSideNavigation />
      <main className="flex-1 flex flex-col">
        <div className="bg-white p-6 flex justify-between items-center w-full shadow-md">
          <div className=" flex justify-evenly items-center w-2/5 ml-20 ">
            <h1 className="text-lg font-semibold">Project Name</h1>
            <h1 className="text-lg font-semibold">Script</h1>
          </div>
          <Button type="primary" onClick={showModal}>
            Sync Script
          </Button>
          <div className=" flex justify-evenly items-center">
            <img src={Mess} style={{ height: 30, width: 35 }} alt="Messages" />
            <img src={User} style={{ height: 30, width: 30 }} alt="User" />
          </div>
        </div>
        <div
          className="editor-container w-4/5 flex flex-col flex-1 p-4 overflow-y-auto ml-40 pl-20"
          style={{ maxWidth: "80vw" }}
        >
          <CustomToolbar />
          <div className="quill-wrapper flex-1 mt-4">
            <ReactQuill
              value={value}
              onChange={setValue}
              modules={modules}
              formats={formats}
              placeholder="Write your script here..."
            />
          </div>
        </div>
      </main>

      <style jsx>{`
        .toolbar {
          display: flex;
          flex-wrap: wrap;
          background: #333;
          color: white;
          padding: 10px;
        }
        .toolbar select,
        .toolbar button {
          margin-right: 10px;
        }
        .ql-container {
          height: 100%;
          border: 1px solid #ccc;
        }
        .editor-container {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .quill-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 768px) {
          .toolbar {
            flex-direction: column;
            align-items: flex-start;
          }
          .toolbar select,
          .toolbar button {
            margin-bottom: 10px;
          }
        }
      `}</style>

      <Modal
        title="Current Screenplay"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <div className="bg-white">
          <div className="border-b-2">
            <div className="flex justify-between items-center p-4">
              <h2>Current Screenplay</h2>
              <div className="flex space-x-2">
                <Link to="/details-colum">
                  <span className="text-pink-500">Save</span>
                </Link>
                <span className="text-red-500">Scenes Removed</span>
                <span className="text-black">Scenes Changed</span>
              </div>
            </div>
          </div>
          <div className="p-4">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Scene
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Changes
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">1</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    Sc. 1 | INT. APARTMENT - DAY
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">1</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">2</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    Sc. 1 | INT. APARTMENT - DAY
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">2</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">3</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    Sc. 1 | INT. APARTMENT - DAY
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">1</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">4</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    Sc. 1 | INT. APARTMENT, BATHROOM - DAY
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">0</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">5</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    Sc. 1 | INT. APARTMENT, BEDROOM - DAY
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">0</td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">6</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    Sc. 1 | INT. HALLWAY - DAY
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">0</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Writescript;
