import React from "react";
import ScriptSideNavigation from "./scriptSideNavigation";
import scriptdashboard from "../../assets/scriptdashboard.png";
import { Link } from "react-router-dom";

function Writetitle() {
  return (
    <div className="flex w-full h-screen">
      <ScriptSideNavigation />
      <main className="flex-1">
        <div className="flex justify-center flex-col items-center overflow-y-hidden h-full">
          <div className="bg-white p-10 flex justify-between items-center py-10 w-full">
            {/* <h1 className="text-lg font-semibold">Script</h1> */}
          </div>
          <div className="flex flex-col items-center justify-center mt-12 w-full">
            {/* <input multiple={true} className="p-64" type="text" /> */}
            <div className="w-full px-4 flex justify-center">
              {/* <textarea
                name="Add Title"
                placeholder="Add Title"
                style={{ backgroundColor: "#d9d9d9" }}
                className="shadow-sm border-2 rounded-2xl p-5 text-gray-500 focus:outline-none"
                id=""
                rows={10}
                cols={70}
                //   aria-multiline
              ></textarea> */}
              <div className="  w-2/5 h-64 flex flex-col ">
                <h4 className="text-4xl font-semibold mb-10">Title</h4>
                <input
                  multiple={true}
                  placeholder="Add Little Title"
                  className="focus:outline-none"
                  style={{
                    color: "#000",
                    borderWidth: 0,
                    borderRadius: 10,
                    padding: 10,

                    backgroundColor: "#d9d9d9",
                  }}
                  type="text"
                />
                <h1 className="text-s   my-10">Version 2.0</h1>
              </div>
            </div>
            <div className="mt-6">
              <Link to="/write-script">
                <button className="bg-pink-400 text-white px-10 py-2 rounded">
                  Start Writing
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Writetitle;
