import dashboardImg from "../assets/main-dashboardimage.png";
import SideNavigation from "./sideNatigation";
import "../App.css";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div>
      <SideNavigation />
      <main
        className="flex-1 bg-gray-100 home-main flex flex-col justify-between"
        //   style={{ width: "80%" }}
      >
        {/* <SideNavigation /> */}
        <div className="flex justify-center items-center flex-col my-7">
          <h1 className="text-3xl md:text-6xl font-semibold text-center mb-6">
            Welcome to Filmyst Studio!
          </h1>
          <p className="text-lg md:text-2xl text-center mb-10">
            Choose one of the options to start your project.
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-full md:w-1/2 mb-5 md:mb-0">
            <img
              src={dashboardImg}
              alt="Illustration of a person working on a computer with a video editing software interface on the screen"
              className="w-full -ml-4"
              style={{ width: "30.5rem" }}
            />
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-center md:items-start px-5 md:items-start pl-60">
            <Link to="/storygenerater">
              <button
                style={{ backgroundColor: "#8c7bff" }}
                className="flex items-center text-white text-lg md:text-xl font-semibold px-6 py-4 rounded mb-4 hover:bg-purple-600"
              >
                <i className="fas fa-plus mr-2 md:mr-5"></i>
                Story Board
              </button>
            </Link>
            <Link to="create-board">
              <button
                style={{ backgroundColor: "#8c7bff" }}
                className="flex items-center text-white text-lg md:text-xl font-semibold px-6 py-4 rounded mb-4 hover:bg-purple-600"
              >
                <i className="fas fa-plus mr-2 md:mr-5"></i>
                Mood Board
              </button>
            </Link>
            <Link to="/city-images">
              <button
                style={{ backgroundColor: "#8c7bff" }}
                className="flex items-center text-white text-lg md:text-xl font-semibold px-6 py-4 rounded mb-4 hover:bg-purple-600"
              >
                <i className="fas fa-plus mr-2 md:mr-5"></i>
                Shotlist
              </button>
            </Link>
          </div>
        </div>
      </main>
    </div>
    // <div className="flex h-screen bg-gray-100 " style={{ marginLeft: "17rem" }}>
    //   {/* <SideNavigation /> */}
    //   <main className="flex-1 ">
    //     <div className="flex justify-center items-center flex-col my-7">
    //       <h1 className="text-6xl font-semibold mb-6">
    //         Welcome to Filmyst Studio !
    //       </h1>
    //       <p className="text-2xl mb-10">
    //         Choose one of the options to start your project.
    //       </p>
    //     </div>

    //     <div className="flex justify-between  ">
    //       <div className=" -ml-5">
    //         <img
    //           src={dashboardImg}
    //           width="68%"
    //           // src="https://placehold.co/600x400"
    //           alt="Illustration of a person working on a computer with a video editing software interface on the screen"
    //           className=" "
    //         />
    //       </div>
    //       <div className="flex flex-col justify-center mr-20 w-64 ">
    //         <button
    //           style={{ backgroundColor: "#8c7bff" }}
    //           className="flex items-center text-white text-xl font-semibold px-6 py-6 rounded mb-4 hover:bg-purple-600"
    //         >
    //           <i className="fas fa-plus mr-5"></i>
    //           Story Board
    //         </button>
    //         <button
    //           style={{ backgroundColor: "#8c7bff" }}
    //           className="flex items-center text-white text-xl font-semibold px-6 py-6 rounded mb-4 hover:bg-purple-600"
    //         >
    //           <i className="fas fa-plus mr-5"></i>
    //           Mood Board
    //         </button>
    //         <button
    //           style={{ backgroundColor: "#8c7bff" }}
    //           className="flex items-center text-white text-xl font-semibold px-6 py-6 rounded mb-4 hover:bg-purple-600"
    //         >
    //           <i className="fas fa-plus mr-5"></i>
    //           Shotlist
    //         </button>
    //       </div>
    //     </div>
    //   </main>
    // </div>
  );
};

export default Home;
