import React from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Input,
  Button,
  Typography,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const styles = {
  layout: {
    height: "100vh",
  },
  sider: {
    background: "#f5f5f5",
    padding: "20px",
    borderRight: "1px solid #ccc",
  },
  content: {
    padding: "20px",
  },
  card: {
    border: "1px solid #ccc",
    padding: "20px",
    marginBottom: "20px",
  },
  notes: {
    marginTop: "20px",
  },
  textarea: {
    width: "100%",
    height: "100px",
  },
};

const Storyboard = () => {
  return (
    <Layout style={styles.layout}>
      <Sider width={200} style={styles.sider}>
        <Title level={4}>SC #</Title>
        <div>
          <Paragraph>Sc. 1 | INT. APART</Paragraph>
          <Paragraph>A cozy apartment filled with warm sunlight.</Paragraph>
          <Paragraph>A clutter of workout gear sits by the door.</Paragraph>
          <Paragraph>The scent of essential oils lingers in the air.</Paragraph>
        </div>
      </Sider>
      <Layout>
        <Content style={styles.content}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card
                title="shot 1"
                extra={<Button>Generate AI</Button>}
                style={styles.card}
              >
                <img
                  src="Up.png"
                  style={{
                    alignSelf: "center",

                    marginInline: "auto",
                  }}
                />
                <Paragraph>
                  A cozy apartment filled with warm sunlight. A clutter of
                  workout gear sits by the door. The scent of essential oils
                  lingers in the air.
                </Paragraph>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Audio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Video" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Shot Size" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Perspective" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Movement" />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="More options" style={styles.card}>
                <img
                  src="Up.png"
                  style={{
                    alignSelf: "center",

                    marginInline: "auto",
                    marginBottom: 15,
                  }}
                />
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Aspect Ratio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Time" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Focal Length FPS" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Equipment" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Filter" />
                  </Col>
                  <Col span={24}>
                    <TextArea placeholder="Notes" rows={1} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card
                title="shot 2"
                extra={<Button>Generate AI</Button>}
                style={styles.card}
              >
                <img
                  src="Up.png"
                  style={{
                    alignSelf: "center",

                    marginInline: "auto",
                  }}
                />
                <Paragraph>
                  A cozy apartment filled with warm sunlight. A clutter of
                  workout gear sits by the door. The scent of essential oils
                  lingers in the air.
                </Paragraph>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Audio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Video" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Shot Size" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Perspective" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Movement" />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="More options" style={styles.card}>
                <img
                  src="Up.png"
                  style={{
                    alignSelf: "center",

                    marginInline: "auto",
                    marginBottom: 15,
                  }}
                />
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Aspect Ratio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Time" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Focal Length FPS" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Equipment" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Filter" />
                  </Col>
                  <Col span={24}>
                    <TextArea placeholder="Notes" rows={1} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Storyboard;
