import React from "react";
import ScriptSideNavigation from "./scriptSideNavigation";
import { Link } from "react-router-dom";

function Generatescript() {
  return (
    <div className="flex w-full h-screen">
      <ScriptSideNavigation />
      <main className="flex-1">
        <div className="flex flex-col items-center justify-start h-full overflow-y-hidden pt-10">
          <div className="w-full flex flex-col items-center">
            <div className="w-2/5 flex flex-col mb-10">
              <h4 className="text-2xl  mb-4" style={{ textAlign: "center" }}>
                Our AI technology helps you branistorm better{" "}
              </h4>
              <h4 className="text-4xl font-semibold mb-4">Title</h4>
              <input
                placeholder="Add Little Title"
                className="focus:outline-none p-3 mb-10"
                style={{
                  color: "#000",
                  borderWidth: 0,
                  borderRadius: 10,
                  backgroundColor: "#d9d9d9",
                }}
                type="text"
              />
              <h4 className="text-4xl font-semibold mb-4">
                Concept Upload PDF
              </h4>
              <input
                placeholder="Add Little Title"
                className="focus:outline-none p-3 mb-10"
                style={{
                  color: "#000",
                  borderWidth: 0,
                  borderRadius: 10,
                  backgroundColor: "#d9d9d9",
                }}
                type="text"
              />

              <h4
                className="text-4xl font-semibold my-10"
                style={{
                  textAlign: "center",
                }}
              >
                ----------- or -----------
              </h4>
              <h4 className="text-4xl font-semibold mb-4">Plotline</h4>
              <input
                placeholder="Add Little Title"
                className="focus:outline-none p-3 mb-10"
                style={{
                  color: "#000",
                  borderWidth: 0,
                  borderRadius: 10,
                  backgroundColor: "#d9d9d9",
                }}
                type="text"
              />
            </div>
            <Link to="/write-script">
              <button className="bg-pink-400 text-white px-10 py-2 rounded">
                Generate Script
              </button>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Generatescript;
