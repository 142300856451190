import React from "react";
import ScriptSideNavigation from "./scriptSideNavigation";
import scriptdashboard from "../../assets/scriptdashboard.png";
import Doc from "../../assets/docs.png";

function Filedrag() {
  return (
    <div className="flex w-full h-screen">
      <ScriptSideNavigation />
      <main className="flex-1">
        <div className="flex justify-center flex-col items-center overflow-y-hidden h-full">
          <div className="bg-white p-10 flex justify-between items-center py-10 w-full">
            {/* <h1 className="text-lg font-semibold">Script</h1> */}
          </div>
          <div className="flex flex-col items-center">
            <img src={Doc} style={{ height: 40, width: 40 }} />
            <h1 className="text-4xl font-semibold">Upload Your Script</h1>
            <h1
              className="text-s "
              style={{
                textAlign: "center",
              }}
            >
              Drag&Drop Files or Upload Files
            </h1>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Filedrag;
