import { Modal } from "antd";
import React, { useState } from "react";
import { FaEye, FaShareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
const Moodboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="text-center font-sans w-full">
      <header className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
        <div className="text-2xl font-bold">Project Name</div>
        <nav className="flex gap-4">
          <button className="text-gray-700 px-4 py-2">Shot Lists</button>
          <button className="text-gray-700 font-bold px-4 py-2">
            Mood Boards
          </button>
          <button className="text-gray-700 px-4 py-2">Story Boards</button>
        </nav>
        <div className="flex gap-4">
          <button className="text-2xl">
            <FaEye />
          </button>
          <button
            onClick={showModal}
            className="flex items-center gap-2 bg-purple-600 text-white px-4 py-2 rounded"
          >
            <FaShareAlt /> Share
          </button>
        </div>
      </header>
      <main className="flex flex-col items-center py-16">
        <div className="text-center max-w-md mx-auto">
          <div className=" rounded-full w-24 h-24 flex justify-center items-center mx-auto mb-4">
            <img className="h-24" src="storyai.png" alt="Mood Board Icon" />
          </div>
          <h2 className="text-xl font-bold mb-2">New Mood Board 1</h2>
          <p className="text-gray-600 mb-4">
            No mood boards have been added to this mood board.
          </p>
          <Link to="/upload-stories">
            <button className="bg-purple-600 text-white px-4 py-2 rounded">
              Add Images
            </button>
          </Link>
        </div>
      </main>
      <Modal
        title="Current Screenplay"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <div className="bg-white">
          <div className="border-b-2">
            <div className="flex justify-between items-center p-4">
              <h2>Current Screenplay</h2>
              <div className="flex space-x-2">
                <span className="text-pink-500">Save</span>
                <span className="text-red-500">Scenes Removed</span>
                <span className="text-black">Scenes Changed</span>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                placeholder="Email, comma separated"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                className="border px-4 py-2 rounded w-full mr-2"
              />
              <select
                // value={role}
                // onChange={(e) => setRole(e.target.value)}
                className="border px-4 py-2 rounded mr-2"
              >
                <option value="Collaborator">Collaborator</option>
                <option value="Viewer">Viewer</option>
              </select>
              <button
                // onClick={handleInvite}
                className="bg-gray-200 px-4 py-2 rounded text-gray-700"
              >
                Invite
              </button>
            </div>
            <div className="mb-4">
              <div className="flex justify-between items-center">
                <span>Only people invited to this file</span>
                <span>can access</span>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    <span>J</span>
                  </div>
                  <span>Jules Addams</span>
                </div>
                <span>owner</span>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    <span>D</span>
                  </div>
                  <span>D. David</span>
                </div>
                <select className="border px-2 py-1 rounded">
                  <option value="can_edit">can edit</option>
                  <option value="can_view">can view</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Moodboard;
