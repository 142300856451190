import React from "react";
import { Table, Button, Input } from "antd";
import { SearchOutlined, BellOutlined, UserOutlined } from "@ant-design/icons";
import "../App.css";

const columns = [
  {
    title: "Shot#",
    dataIndex: "shotNumber",
    key: "shotNumber",
  },
  {
    title: "INT/EXT",
    dataIndex: "intExt",
    key: "intExt",
  },
  {
    title: "Dialogue",
    dataIndex: "dialogue",
    key: "dialogue",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Shot Size",
    dataIndex: "shotSize",
    key: "shotSize",
  },
  {
    title: "Camera Angle",
    dataIndex: "cameraAngle",
    key: "cameraAngle",
  },
  {
    title: "Camera Movement",
    dataIndex: "cameraMovement",
    key: "cameraMovement",
  },
];

const dataSource = [
  {
    key: "1",
    shotNumber: "1",
    intExt: "INT",
    dialogue: 'JESSICA "Look at these action shots stacking up!" See more...',
    action: "[Excitedly checking the film takes tracker]",
    shotSize: "Medium Shot",
    cameraAngle: "Low",
    cameraMovement: "Pan Right",
    notes: "The room is adorned with motivational quotes and fitness awards",
  },
  // Add more data as needed
];

function Columdetails() {
  return (
    <div className="bg-gray-100 min-h-screen w-full overflow-y-auto">
      <div className="max-w-6xl mx-auto py-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-semibold">Shot List</h1>
          <div className="flex items-center">
            <Button
              type="link"
              className="mr-4 text-gray-600"
              icon={<SearchOutlined />}
            >
              Back
            </Button>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined className="text-gray-400" />}
              className="mr-4"
            />
            <Button type="link" icon={<BellOutlined />} className="ml-4" />
            <Button type="link" icon={<UserOutlined />} className="ml-4" />
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-6 mb-4">
          <h2 className="text-lg font-semibold mb-4">Project Name</h2>
          <div className="border-b pb-4 mb-4">
            <h3 className="text-gray-600 font-semibold">
              Sc.1 INT. Apartment - Morning
            </h3>
          </div>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
          />
        </div>
        <div className="bg-white shadow rounded-lg p-6 mb-4">
          <div className="border-b pb-4 mb-4">
            <h3 className="text-gray-600 font-semibold">
              Sc.2 INT. Apartment - Morning
            </h3>
          </div>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
          />
        </div>
        <div className="flex justify-center mt-6">
          <Button
            type="primary"
            className="bg-pink-500 text-white px-8 py-2 rounded-full"
          >
            Export
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Columdetails;
// import React, { useState } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css"; // Import Quill styles

// const CustomButton = () => <span className="ql-custom">C</span>;

// function insertCustomText() {
//   const quill = this.quill;
//   const range = quill.getSelection();
//   if (range) {
//     quill.insertText(range.index, "Custom Text", "bold", true);
//   }
// }

// function imageHandler() {
//   const input = document.createElement("input");
//   input.setAttribute("type", "file");
//   input.setAttribute("accept", "image/*");
//   input.click();

//   input.onchange = () => {
//     const file = input.files[0];
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const quill = this.quill;
//       const range = quill.getSelection();
//       quill.insertEmbed(range.index, "image", e.target.result);
//     };
//     reader.readAsDataURL(file);
//   };
// }

// const modules = {
//   toolbar: {
//     container: [
//       [{ header: "1" }, { header: "2" }, { font: [] }],
//       [{ size: [] }],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [
//         { list: "ordered" },
//         { list: "bullet" },
//         { indent: "-1" },
//         { indent: "+1" },
//       ],
//       ["link", "image", "video"],
//       ["clean"],
//       ["customButton"], // Add custom button to the toolbar
//     ],
//     handlers: {
//       customButton: insertCustomText, // Custom button handler
//       image: imageHandler, // Custom image handler
//     },
//   },
// };

// const formats = [
//   "header",
//   "font",
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "bullet",
//   "indent",
//   "link",
//   "image",
//   "video",
// ];

// const MyEditor = () => {
//   const [value, setValue] = useState("");

//   return (
//     <div>
//       <ReactQuill
//         theme="snow"
//         value={value}
//         onChange={setValue}
//         modules={modules}
//         formats={formats}
//       />
//     </div>
//   );
// };

// export default MyEditor;
