import React from "react";
import bigImage1 from "../assets/citygirl1.png";
import bigImage2 from "../assets/bakery.png";
import { Link } from "react-router-dom";
import SideNavigation from "./sideNatigation";

const CityImages = () => {
  const images = [
    { src: bigImage1, title: "City Girl 1" },
    { src: bigImage2, title: "Bakery" },
    { src: bigImage1, title: "City Girl 2" },
    { src: bigImage2, title: "Bakery 2" },
  ];

  return (
    <div>
      <SideNavigation />
      <div className="flex-1 bg-gray-100 home-main flex flex-col justify-between h-screen">
        {/* <h2 className="text-2xl font-semibold mb-4">City Images</h2> */}
        <div className="flex flex-wrap justify-center h-full overflow-auto p-10">
          {images.map((image, index) => (
            <div key={index} className="w-2/2 p-8">
              <Link to="/project-detail">
                <img
                  src={image.src}
                  alt={`Image ${index + 1}`}
                  className="w-full rounded-lg mb-2"
                />
              </Link>
              <h3 className="text-lg font-semibold">{image.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CityImages;
