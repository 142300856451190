import React from "react";
import "../App.css";
import { Link } from "react-router-dom";

function Colums() {
  return (
    <div style={styles.app}>
      <Sidebar />
      <ShotList />
      <ShotDetail />
    </div>
  );
}

function Sidebar() {
  return (
    <div style={styles.sidebar}>
      <div style={styles.importedLists}>
        <h2>Imported lists</h2>
        <ul style={styles.ul}>
          <li>Sc. 1 INT. APART</li>
          <li>Sc. 2 INT.</li>
          <li>Sc. 3 INT.</li>
          <li>Sc. 4 INT.</li>
          <li>Sc. 5 INT.</li>
          <li>Sc. 6 INT.</li>
          <li>Sc. 7 EXT.</li>
          <li>Sc. 8 EXT.</li>
          <li>Sc. 9 INT.</li>
          <li>Sc. 10 INT.</li>
        </ul>
      </div>
    </div>
  );
}

function ShotList() {
  return (
    <div style={styles.shotList}>
      <h3>Sc.1 INT. Apartment - Morning</h3>
      <p>
        A cozy apartment filled with warm sunlight. A cluster of bookshelves
        greet by the door. The scent of essential oils lingers in the air.
      </p>
    </div>
  );
}

function ShotDetail() {
  return (
    <div style={styles.shotDetail}>
      <table style={styles.table}>
        <thead>
          <tr>
            <th>Shot#</th>
            <th>INT/EXT</th>
            <th>Dialogue</th>
            <th>Action</th>
            <th>Shot Size</th>
            <th>Camera Angle</th>
            <th>Camera Movement</th>
            <th>Notes</th>
            <th>Ref.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="9"></td>
          </tr>
        </tbody>
      </table>
      <GenerateButton />
    </div>
  );
}

function GenerateButton() {
  return (
    <div style={styles.generateButton}>
      <Link to="/colums-details">
        <button style={styles.button}>Generate Shotlist</button>
      </Link>
    </div>
  );
}

const styles = {
  app: {
    display: "flex",
    height: "100vh",
  },
  sidebar: {
    width: "20%",
    background: "#f3f4f6",
    padding: "20px",
  },
  importedLists: {
    fontSize: "14px",
  },
  ul: {
    listStyleType: "none",
    padding: 0,
  },
  shotList: {
    width: "20%",
    padding: "20px",
    background: "#fff",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  shotDetail: {
    width: "50%",
    padding: "20px",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
    height: 200,
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  generateButton: {
    width: "100%",
    textAlign: "center",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#ff007f",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
};

export default Colums;
