import React from "react";
import ScriptSideNavigation from "./scriptSideNavigation";
import scriptdashboard from "../../assets/scriptdashboard.png";
import { Link } from "react-router-dom";

function Titlescript() {
  return (
    <div className="flex w-full h-screen">
      <ScriptSideNavigation />
      <main className="flex-1">
        <div className="flex justify-center flex-col items-center overflow-y-hidden h-full">
          <div className="bg-white p-10 flex justify-between items-center py-10 w-full">
            {/* <h1 className="text-lg font-semibold">Script</h1> */}
          </div>
          <div className="flex flex-col items-center justify-center mt-12 w-full">
            {/* <input multiple={true} className="p-64" type="text" /> */}
            <div className="w-full px-4 flex justify-center">
              {/* <textarea
                name="Add Title"
                placeholder="Add Title"
                style={{ backgroundColor: "#d9d9d9" }}
                className="shadow-sm border-2 rounded-2xl p-5 text-gray-500 focus:outline-none"
                id=""
                rows={10}
                cols={70}
                //   aria-multiline
              ></textarea> */}
              <div
                className=" shadow-sm border-2 rounded-2xl p-5 text-gray-500 focus:outline-none w-2/5 h-64 flex flex-col items-center justify-center"
                style={{ backgroundColor: "#d9d9d9" }}
              >
                <input
                  multiple={true}
                  placeholder="Add Little Title"
                  className="focus:outline-none"
                  style={{
                    color: "#000",
                    borderWidth: 0,
                    borderRadius: 10,
                    padding: 20,
                    fontSize: 30,
                    textAlign: "center",
                    alignSelf: "center",
                    backgroundColor: "#d9d9d9",
                  }}
                  type="text"
                />
                <input
                  placeholder="Auther"
                  className="focus:outline-none"
                  style={{
                    color: "#000",
                    borderWidth: 0,
                    borderRadius: 10,
                    padding: 10,
                    fontSize: 18,
                    textAlign: "center",
                    alignSelf: "center",
                    backgroundColor: "#d9d9d9",
                  }}
                  type="text"
                />
                <input
                  placeholder="version 2"
                  className="focus:outline-none"
                  style={{
                    color: "#000",
                    borderWidth: 0,
                    borderRadius: 10,
                    padding: 10,
                    fontSize: 18,
                    textAlign: "center",
                    alignSelf: "center",
                    backgroundColor: "#d9d9d9",
                  }}
                  type="text"
                />
              </div>
            </div>
            <div className="mt-6">
              <Link to="/file-drag">
                <button className="bg-pink-400 text-white px-10 py-2 rounded">
                  Upload
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Titlescript;
