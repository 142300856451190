import React from "react";
import createboard from "../assets/createboard.png";
import SideNavigation from "./sideNatigation";
import { Link } from "react-router-dom";

function createBoard() {
  return (
    <div>
      <SideNavigation />
      <div className="flex-1 bg-gray-100 home-main flex flex-col justify-between h-screen">
        <div className="flex justify-center mb-2">
          <img src={createboard} alt="Logo" className="h-40" />
        </div>
        <div className="mb-8 text-center">
          <h2 className="text-6xl font-semibold mb-2">
            Create your Mood Board
          </h2>
        </div>
        <div className="w-full p-8 " style={{ width: "90%" }}>
          <div className="mb-8">
            <h2 className="text-3xl font-semibold mb-2">New Mood Board</h2>
            <div className="w-full border-b-4 rounded-lg  border-purple-500 mt-4"></div>
            <input
              type="text"
              placeholder="Enter Description..."
              className="w-full p-5 mt-5 border-b-2 border-purple-500 mb-4 focus:outline-none italic text-xl "
            />
          </div>
          <div className="flex justify-center">
            <Link
              //  to="/city-images"
              to="/mood-board"
            >
              <button
                style={{ backgroundColor: "#6e3ffa" }}
                className="w-100 p-5  px-20 text-white text-2xl  rounded hover:bg-purple-700 focus:outline-none"
              >
                Create Mood Board
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default createBoard;
