import React from "react";
import { FaCrop, FaAdjust, FaFilter, FaArrowUp } from "react-icons/fa";
function Picedit() {
  const styles = {
    app: {
      display: "flex",
      height: "100vh",
      fontFamily: "Arial, sans-serif",
    },
    toolbar: {
      width: "60px",
      backgroundColor: "#F5F5F5",
      display: "flex",
      flexDirection: "column",
      paddingTop: "20px",
      alignItems: "center",
    },
    toolbarButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "20px",
      background: "none",
      border: "none",
      cursor: "pointer",
      color: "#333",
    },
    toolbarIcon: {
      fontSize: "24px",
      marginBottom: "5px",
    },
    imageEditor: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
    },
    imageContainer: {
      border: "1px solid #ccc",
      padding: "10px",
    },
    imageControls: {
      marginTop: "20px",
    },
    imageControlButton: {
      padding: "10px",
      backgroundColor: "#ddd",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      margin: "0 5px",
    },
    saveButton: {
      position: "absolute",
      top: "20px",
      right: "20px",
      padding: "10px 20px",
      backgroundColor: "#6200EA",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };
  return (
    <div style={styles.app}>
      <div style={styles.toolbar}>
        <button style={styles.toolbarButton}>
          <FaCrop style={styles.toolbarIcon} /> Crop
        </button>
        <button style={styles.toolbarButton}>
          <FaAdjust style={styles.toolbarIcon} /> Finetune
        </button>
        <button style={styles.toolbarButton}>
          <FaFilter style={styles.toolbarIcon} /> Filter
        </button>
        <button style={styles.toolbarButton}>
          <FaArrowUp style={styles.toolbarIcon} /> Arrows
        </button>
      </div>
      <div style={styles.imageEditor}>
        <div style={styles.imageContainer}>
          <img
            src="pic.png"
            style={{
              width: 400,
            }}
            alt="Editable"
          />
        </div>
        <div style={styles.imageControls}>
          <button style={styles.imageControlButton}>Rotate</button>
          <button style={styles.imageControlButton}>Flip</button>
          <button style={styles.imageControlButton}>Crop</button>
        </div>
      </div>
      <button style={styles.saveButton}>Save</button>
    </div>
  );
}
export default Picedit;
