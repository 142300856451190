import { useState } from "react";

const SideNavigation = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  return (
    <aside className="w-64 bg-white p-6">
      <div className="mb-8">
        <div className="flex items-center mb-4">
          <i className="fas fa-user-circle text-gray-600 text-2xl"></i>
          <span className="ml-2 text-sm font-semibold">Username</span>
        </div>
        <div className="flex items-center mb-4">
          <i className="fas fa-search text-gray-400"></i>
          <input
            type="text"
            placeholder="Search files, teams, or people"
            className="ml-2 text-sm p-1 focus:outline-none"
          />
        </div>
        <nav>
          <a
            href="/*"
            className="flex items-center text-sm py-2 text-gray-600 hover:text-gray-800"
          >
            <i className="fas fa-clock mr-2"></i>
            Recents
          </a>
          <hr />
          <a
            href="/*"
            className="flex items-center text-sm py-2 text-gray-600 hover:text-gray-800"
          >
            <i className="fas fa-users mr-2"></i>
            Teams
          </a>
          <a
            href="/*"
            className="flex items-center text-sm py-2 text-gray-600 hover:text-gray-800"
          >
            <i className="fas fa-file-alt mr-2"></i>
            Drafts
          </a>
          <hr />
        </nav>
      </div>
      <div className="absolute bottom-0 flex gap-10">
        <a
          href="/*"
          className="flex items-center text-sm py-2 text-gray-600 hover:text-gray-800"
        >
          <i className="fas fa-cog mr-2"></i>
          Settings
        </a>
        <a
          href="/*"
          className="flex items-center text-sm py-2 text-gray-600 hover:text-gray-800"
        >
          <i className="fas fa-question-circle mr-2"></i>
          Help
        </a>
      </div>
    </aside>
  );
};

export default SideNavigation;
