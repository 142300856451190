import logo from "./logo.svg";
import "./App.css";

import AppRoute from "./routes/index";
import Home from "./screens/Home";
function App() {
  return (
    <div className="flex h-screen">
      <AppRoute />
      {/* <Home /> */}
    </div>
  );
}

export default App;
