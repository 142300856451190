import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import SideNavigation from "../screens/sideNatigation";
import Home from "../screens/Home";
import CreateBoard from "../screens/createBoard";
import CityImages from "../screens/cityImages";
import ScriptDashboard from "../screens/scriptScreen/ScriptScreen";
import Titlescript from "../screens/scriptScreen/Titlescript";
import Writescript from "../screens/scriptScreen/Writescript";
import Writetitle from "../screens/scriptScreen/Writetitle";
import Filedrag from "../screens/scriptScreen/Filedrag";
import Generatescript from "../screens/scriptScreen/Generatescript";
import Projectdetail from "../screens/Projectdetail";
import ModalComponent from "../screens/Test";
import Colums from "../screens/Colums";
import Columdetails from "../screens/Columdetail";
import Uploadothers from "../screens/Upload&others";
import Storyboard from "../screens/storyboard";
import Generatestory from "../screens/Generatestory";
import Visual from "../screens/Visual";
import Moodboard from "../screens/Moodboard";
import Uploadstroies from "../screens/Uploadstrories";
import Withpic from "../screens/Withpic";
import Picedit from "../screens/Picedit";
import PageNotFound from "../screens/PageNotFound";

function AppRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create-board" element={<CreateBoard />} />
        <Route path="/city-images" element={<CityImages />} />
        <Route path="/script-dashboard" element={<ScriptDashboard />} />
        <Route path="/title-script" element={<Titlescript />} />
        <Route path="/write-script" element={<Writescript />} />
        <Route path="/write-title" element={<Writetitle />} />
        <Route path="/file-drag" element={<Filedrag />} />
        <Route path="/generate-script" element={<Generatescript />} />
        <Route path="/project-detail" element={<Projectdetail />} />
        <Route path="/modal-detail" element={<ModalComponent />} />
        <Route path="/details-colum" element={<Colums />} />
        <Route path="/colums-details" element={<Columdetails />} />
        <Route path="/uploads" element={<Uploadothers />} />
        <Route path="/story" element={<Storyboard />} />
        <Route path="/storygenerater" element={<Generatestory />} />
        <Route path="/visual" element={<Visual />} />
        <Route path="/mood-board" element={<Moodboard />} />
        <Route path="/upload-stories" element={<Uploadstroies />} />
        <Route path="/pic-stories" element={<Withpic />} />
        <Route path="/pic-edit" element={<Picedit />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoute;
