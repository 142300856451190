import React from "react";
import { Table, Button, Input } from "antd";
import { SearchOutlined, BellOutlined, UserOutlined } from "@ant-design/icons";
import "../App.css";

function Uploadothers() {
  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="flex justify-between items-start">
        <div className="text-3xl font-bold">Project Name</div>
        <div className="text-3xl">Storyboard AI</div>
      </div>
      <div className="flex mt-8">
        <div className="flex-1 bg-white shadow rounded-lg p-6 mr-6">
          <div className="flex items-center justify-between mb-4">
            <div className="text-lg font-semibold">SC #</div>
            <div className="text-lg font-semibold">Script</div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-sm font-semibold">1</div>
            <div className="text-sm">Sc. 1 | INT. APART</div>
            <div className="text-sm">
              A cozy apartment filled with warm sunlight.
            </div>
            <div className="text-sm">
              A clutter of workout gear sits by the door.
            </div>
            <div className="text-sm">
              The scent of essential oils lingers in the air.
            </div>
          </div>
        </div>
        <div className="flex-1 bg-white shadow rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <div className="text-lg font-semibold">More options</div>
            <div className="text-gray-400">Reference</div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-sm">
              <div className="mb-2">Aspect Ratio</div>
              <div className="mb-2">Time</div>
              <div className="mb-2">Focal Length FPS</div>
              <div className="mb-2">Equipment</div>
              <div className="mb-2">Filter</div>
            </div>
            <div className="text-sm">
              <div className="mb-2">Notes</div>
              <textarea
                className="border rounded w-full p-2 text-xs"
                rows="5"
                placeholder="Add a note or @ mention"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Uploadothers;
