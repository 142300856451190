import React from "react";
import { FaPenNib, FaClipboardList, FaImages, FaTasks } from "react-icons/fa"; // Importing FontAwesome icons
import { Link } from "react-router-dom";
import SideNavigation from "./sideNatigation";

const Projectdetail = () => {
  const stages = [
    {
      name: "Script",
      status: "Synced",
      icon: <FaPenNib />,
      bgColor: "bg-purple-100",
      textColor: "text-purple-500",
      borderColor: "border-purple-500",
    },
    {
      name: "Moodboard",
      status: "Under Review",
      icon: <FaImages />,
      bgColor: "bg-green-100",
      textColor: "text-green-500",
      borderColor: "border-green-500",
    },
    {
      name: "Shotlist",
      status: "In Progress",
      icon: <FaClipboardList />,
      bgColor: "bg-red-100",
      textColor: "text-red-500",
      borderColor: "border-red-500",
    },
    {
      name: "Storyboard",
      status: "Done",
      icon: <FaTasks />,
      bgColor: "bg-orange-100",
      textColor: "text-orange-500",
      borderColor: "border-orange-500",
    },
  ];

  return (
    <div>
      <SideNavigation />
      <div className="flex-1 bg-gray-100 home-main flex flex-col justify-between h-screen">
        <div className="flex flex-col h-full bg-white p-10">
          <h2 className="text-2xl font-semibold mb-4">Project Name</h2>
          <div className="m-40">
            <div className="grid w-2/3 grid-cols-2 gap-10">
              {stages.map((stage, index) => (
                <Link
                  to={
                    index === 0
                      ? "/script-dashboard"
                      : index === 1
                      ? "/create-board"
                      : index === 2
                      ? "/city-images"
                      : "/storygenerater"
                  }
                >
                  <div
                    key={index}
                    className={`flex  items-center justify-center p-6  border rounded-lg ${stage.borderColor} ${stage.bgColor}`}
                  >
                    <div className={`text-4xl mb-2 ${stage.textColor}`}>
                      {stage.icon}
                    </div>
                    <div className="ml-20">
                      <h3 className="text-lg font-semibold mb-2">
                        {stage.name}
                      </h3>
                      <p className={`text-sm ${stage.textColor}`}>
                        {stage.status}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projectdetail;
