import React, { useState } from "react";
import { Layout, Row, Col, Card, Input, Button, Typography, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FaEye, FaShareAlt } from "react-icons/fa";

const { Header, Sider, Content } = Layout;
const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const styles = {
  layout: {
    height: "100vh",
  },
  sider: {
    background: "#f5f5f5",
    padding: "20px",
    borderRight: "1px solid #ccc",
  },
  content: {
    padding: "20px",
  },
  card: {
    border: "1px solid #ccc",
    padding: "20px",
    marginBottom: "20px",
  },
  notes: {
    marginTop: "20px",
  },
  textarea: {
    width: "100%",
    height: "100px",
  },
};

const UploadStories = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  <Modal
    title="Current Screenplay"
    visible={isModalVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    width={800}
  >
    <div className="bg-white">
      <div className="border-b-2">
        <div className="flex justify-between items-center p-4">
          <h2>Current Screenplay</h2>
          <div className="flex space-x-2">
            <span className="text-pink-500">Save</span>
            <span className="text-red-500">Scenes Removed</span>
            <span className="text-black">Scenes Changed</span>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder="Email, comma separated"
            // value={email}
            // onChange={(e) => setEmail(e.target.value)}
            className="border px-4 py-2 rounded w-full mr-2"
          />
          <select
            // value={role}
            // onChange={(e) => setRole(e.target.value)}
            className="border px-4 py-2 rounded mr-2"
          >
            <option value="Collaborator">Collaborator</option>
            <option value="Viewer">Viewer</option>
          </select>
          <button
            // onClick={handleInvite}
            className="bg-gray-200 px-4 py-2 rounded text-gray-700"
          >
            Invite
          </button>
        </div>
        <div className="mb-4">
          <div className="flex justify-between items-center">
            <span>Only people invited to this file</span>
            <span>can access</span>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                <span>J</span>
              </div>
              <span>Jules Addams</span>
            </div>
            <span>owner</span>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="flex items-center space-x-2">
              <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                <span>D</span>
              </div>
              <span>D. David</span>
            </div>
            <select className="border px-2 py-1 rounded">
              <option value="can_edit">can edit</option>
              <option value="can_view">can view</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </Modal>;
  return (
    <Layout style={styles.layout}>
      <Header className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
        <div className="text-2xl font-bold">Project Name</div>
        <nav className="flex gap-4">
          <Button className="text-gray-700">Shot Lists</Button>
          <Button className="text-gray-700 font-bold ">Mood Boards</Button>
          <Button className="text-gray-700 ">Story Boards</Button>
        </nav>
        <div className="flex gap-4">
          <Button className="text-2xl">
            <FaEye />
          </Button>
          <Button
            onClick={showModal}
            className="flex items-center gap-2 bg-purple-600 text-white px-4 py-2 rounded"
          >
            <FaShareAlt /> Share
          </Button>
        </div>
      </Header>
      <Layout className="overflow-y-auto">
        <Content style={styles.content}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card
                title="Shot 1"
                extra={<Button>Generate AI</Button>}
                style={styles.card}
              >
                <img
                  src="Up.png"
                  alt="Shot 1"
                  style={{
                    alignSelf: "center",
                    marginInline: "auto",
                  }}
                />
                <Paragraph>
                  A cozy apartment filled with warm sunlight. A clutter of
                  workout gear sits by the door. The scent of essential oils
                  lingers in the air.
                </Paragraph>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Audio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Video" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Shot Size" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Perspective" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Movement" />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="More options" style={styles.card}>
                <img
                  src="Up.png"
                  alt="More options"
                  style={{
                    alignSelf: "center",
                    marginInline: "auto",
                    marginBottom: 15,
                  }}
                />
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Aspect Ratio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Time" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Focal Length FPS" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Equipment" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Filter" />
                  </Col>
                  <Col span={24}>
                    <TextArea placeholder="Notes" rows={1} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card
                title="Shot 2"
                extra={<Button>Generate AI</Button>}
                style={styles.card}
              >
                <img
                  src="Up.png"
                  alt="Shot 2"
                  style={{
                    alignSelf: "center",
                    marginInline: "auto",
                  }}
                />
                <Paragraph>
                  A cozy apartment filled with warm sunlight. A clutter of
                  workout gear sits by the door. The scent of essential oils
                  lingers in the air.
                </Paragraph>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Audio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Video" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Shot Size" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Perspective" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Movement" />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="More options" style={styles.card}>
                <img
                  src="Up.png"
                  alt="More options"
                  style={{
                    alignSelf: "center",
                    marginInline: "auto",
                    marginBottom: 15,
                  }}
                />
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Input placeholder="Aspect Ratio" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Time" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Focal Length FPS" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Equipment" />
                  </Col>
                  <Col span={12}>
                    <Input placeholder="Filter" />
                  </Col>
                  <Col span={24}>
                    <TextArea placeholder="Notes" rows={1} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
      <Modal
        title="Current Screenplay"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <div className="bg-white">
          <div className="border-b-2">
            <div className="flex justify-between items-center p-4">
              <h2>Current Screenplay</h2>
              <div className="flex space-x-2">
                <span className="text-pink-500">Save</span>
                <span className="text-red-500">Scenes Removed</span>
                <span className="text-black">Scenes Changed</span>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                placeholder="Email, comma separated"
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                className="border px-4 py-2 rounded w-full mr-2"
              />
              <select
                // value={role}
                // onChange={(e) => setRole(e.target.value)}
                className="border px-4 py-2 rounded mr-2"
              >
                <option value="Collaborator">Collaborator</option>
                <option value="Viewer">Viewer</option>
              </select>
              <button
                // onClick={handleInvite}
                className="bg-gray-200 px-4 py-2 rounded text-gray-700"
              >
                Invite
              </button>
            </div>
            <div className="mb-4">
              <div className="flex justify-between items-center">
                <span>Only people invited to this file</span>
                <span>can access</span>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    <span>J</span>
                  </div>
                  <span>Jules Addams</span>
                </div>
                <span>owner</span>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center space-x-2">
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    <span>D</span>
                  </div>
                  <span>D. David</span>
                </div>
                <select className="border px-2 py-1 rounded">
                  <option value="can_edit">can edit</option>
                  <option value="can_view">can view</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default UploadStories;
